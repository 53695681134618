import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';

export const effeGoldCustomerGroup = 'effe_gold';
export const customerGroup = effeGoldCustomerGroup;

const supportEmail = {
	effe_gold: 'effe_gold@smoothr.de'
};
const firebaseConfig = {
	effe_gold: {
		apiKey: "AIzaSyAysxxEggjX3jJ4sRK4yngdAp_npuODSpc",
		authDomain: "effe-and-gold.firebaseapp.com",
		projectId: "effe-and-gold",
		storageBucket: "effe-and-gold.appspot.com",
		messagingSenderId: "839475549307",
		appId: "1:839475549307:web:4c55536702a0e852c87ede",
		measurementId: "G-0KHF73079S"
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyC2HH3KKfFNPEbBa5w8MsS5i4hBA4kcfAE',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['de']
};

import 'zone.js/dist/zone-error';
