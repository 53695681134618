import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const effeGoldCustomerGroup = 'effe_gold';
export const customerGroup = effeGoldCustomerGroup;

const supportEmail = {
	effe_gold: 'effe_gold@smoothr.de'
};
const firebaseConfig = {
	effe_gold: {
			apiKey: "AIzaSyAysxxEggjX3jJ4sRK4yngdAp_npuODSpc",
			authDomain: "effe-and-gold.firebaseapp.com",
			projectId: "effe-and-gold",
			storageBucket: "effe-and-gold.appspot.com",
			messagingSenderId: "839475549307",
			appId: "1:839475549307:web:2989c860c8d10869c87ede",
			measurementId: "G-KFLJBVNC1L"
		}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyB75z4J2W6c7DhXesVLEMb3Dd37zFJda3w',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
	countryList: ['de']
};
